
    import exampleQuiz from '@/assets/quizzes/example-quiz.json';
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class DevDataStructures extends Vue {
        // Data function
        public data() {
            return {
                leadProgrammerIconPathAndName:
                    require("@/assets/images/icon_lead_programmer.png"),

                exampleQuiz:
                    require("@/assets/quizzes/example-quiz.json"),
            };
        }
    }
